<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="标题:">
                    <el-input v-model="queryParams.name" placeholder="请输入名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
                <div class="action">
                    <el-button type="primary" @click="add()">新增</el-button>
                </div>
            </el-form>
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
                <!-- <el-table-column width="55" type="selection" align="center"></el-table-column> -->
                <el-table-column prop="id" label="id" align="center" width="100"></el-table-column>
                <el-table-column label="图片" align="center" width="80" prop="image">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img1')" style="height:23px;width:30px;" :class="`img1column${scope.$index}`" class="" fit="cover" v-if="scope.row.image" :src="scope.row.image" :preview-src-list="[scope.row.image]">
                        </el-image>
                        <i v-else class="el-icon-picture-outline avatar"></i>
                    </template>
                </el-table-column>
                <el-table-column label="logo图标" align="center" width="80" prop="logo">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img2')" style="height:23px;width:30px;" :class="`img2column${scope.$index}`" fit="cover" v-if="scope.row.logo" :src="scope.row.logo" :preview-src-list="[scope.row.logo]"></el-image>
                        <i v-else class="el-icon-picture-outline avatar"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="分组名称" align="center"></el-table-column>
                <el-table-column prop="desc" label="描述" align="center"></el-table-column>
                <el-table-column prop="ctime" label="创建时间" align="center" width="170"></el-table-column>
                <el-table-column label="状态" prop="state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                    查看
                </el-table-column>
            </el-table>
            <div class="pagination">
                <!-- <div class="delbtn">
                    <el-button type="info" plain>删除分组</el-button>
                </div>-->
                <!-- 分页 -->
                <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
            </div>
            <el-dialog :title="title" :visible.sync="addVisible" width="30%" :before-close="handleClose" class="dialog_box">
                <el-form ref="form" v-bind:rules="rules" v-bind:model="form" label-position="right" v-loading.body="loading" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                    <el-form-item label="分组名称" prop="name">
                        <el-input placeholder="请输入分组名称" v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="分组描述" prop="desc">
                        <el-input placeholder="分组描述" type="textarea" v-model="form.desc" style="width:200px">
                        </el-input>
                    </el-form-item>
                    <!-- 图片上传 -->
                    <el-form-item label="图片" prop="image">
                        <el-input v-model="form.image" placeholder="请输入图片链接"></el-input>
                        <el-button @click.prevent="choiceImg('image')">选择图片</el-button>
                        <el-upload class="avatar-uploader up img1" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
                            <img v-if="form.image" :src="form.image" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <span class="span_gray">建议尺寸100*100像素</span>
                    </el-form-item>
                    <!-- logo上传 -->
                    <el-form-item label="logo图标" prop="logo">
                        <el-input v-model="form.logo" placeholder="请输入logo链接"></el-input>
                        <el-button @click.prevent="choiceImg('logo')">选择图片</el-button>
                        <el-upload class="avatar-uploader up img2" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleLogoSuccess" :before-upload="beforeLogoUpload" name="file" :headers="header">
                            <img v-if="form.logo" :src="form.logo" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <span class="span_gray">建议尺寸100*100像素</span>
                    </el-form-item>
                    <el-form-item label="状态" prop="state">
                        <el-radio-group v-model="form.state">
                            <el-radio :label="2">上线</el-radio>
                            <el-radio :label="1">下线</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="handleClose">取消</el-button>
                        <el-button type="primary" @click="saveData">确认</el-button>
                    </el-form-item>

                </el-form>
            </el-dialog>
            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 选择图片对话框 -->
            <div class="img">
                <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import loadEvents from '@/utils/loading'
import Picture from '@/components/Picture';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";
let loads = new loadEvents();
export default {
    name: 'goodsLabel',
    components: {
        Pagination,
        Breadcrumb,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            text: 'image',
            header: {},
            mode: '',
            menuList: ['商品', '商品分组'],
            addVisible: false,
            queryParams: {
                page: 1,
                pagesize: 10,

            },
            form: {},
            pageInfo: {},
            title: '新增',
            loading: false,
            rules: {
                name: [{ required: true, message: '标签名称不能为空', trigger: 'blur' }],
                desc: [{ required: true, message: '标签描述不能为空', trigger: 'blur' }],
                image: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                logo: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],

            },
            confirmVisible: false,
            confirmContent: '',
        };
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
    },
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;

        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, this.text, url);
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        //取消添加标签
        handleClose() {
            this.addVisible = false;
            this.form = {};
            this.title = '新增标签';
        },

        //获取商品分组列表
        loadData() {
            this.loading = true
            request.get('/goods/group/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //添加分组

        add() {
            this.mode = 'add';
            this.title = '新增';
            this.form = {
                state: 2,
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //编辑分组
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑';
            //处理时间
            this.form = Object.assign({}, row);
            this.addVisible = true;
        },
        //删除标签
        delete(val) {
            var delIds = [];
            delIds.push(val);
            request.post('/goods/tag/delete', delIds).then(ret => {
                if (ret.code == 200) {
                    this.$message.success(ret.msg);
                    this.getLabelList();
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //确定修改或添加
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/goods/group/add' : '/goods/group/edit';
                        //处理时间
                        console.log(5555, this.form)
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.form = {};
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        //搜索
        searchList() {
            this.getLabelList();
        },
        //图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'image', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            loads.open(`.img1 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        handleLogoSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        beforeLogoUpload(file) {
            loads.open(`.img2 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        // 查看大图
        showBigImg(index, node) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`${node}column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.form.ctime = deal.timestamp(this.form.ctime);
            this.confirmVisible = true;
        },
        // 确定下线
        stateData() {
            request.post('/goods/group/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 20px;
}
</style>
